@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;



 [data-sparta-container] .#{$moduleNameSpace} {
  margin: 0;

  a.spa-btn {
    &--small {
      line-height: 1;
      margin-right: 10px;
    }

    &--medium {
      line-height: 1.3;
    }
  }

  .engagement-container {
    position: relative;
    padding: 0 !important; 


    @media #{$medium-up} {
      min-height: 424px;
      background-color: $snow;
      padding-top: 70px !important;
    }
    @media screen and (min-width: 1266px) {
      min-height: none;
      padding-top: 0px !important;
    }


    .engagement-children {
      @media #{$medium-only} {
        padding-top: $spacing-10;
      }
    }

    .engagement-content {
      padding-left: $spacing-10;
      @media #{$large-up} {
        padding-top: $spacing-20;
      }
    }

    .background-overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 40%;

      @media #{$small-only} {
        -webkit-transform: translateY(-40%);
        transform: translateY(-40%);
        text-align: center;

        .buttons {
          text-align: center;
          bottom: 10px;

          a {
            @media #{$medium-only} {
              padding: $spacing-10 $spacing-20;
              font-size: 20px;
            }
          }
        }
      }

      @media #{$medium-up} {
        right: auto;
        left: auto;
        top: 0;
      }
      @media #{$large-up} {
        right: auto;
        left: auto;
        top: 20px;
      }

      .buttons {
        @media #{$small-only} {
          text-align: left;
        }
      }

      h2 {
        font-size: 20px;
        line-height: 1.1;
        max-width: 70%;
        text-align: left;

        @media #{$medium-up} {
          margin-top: $spacing-40;
          margin-bottom: $spacing-20;
          max-width: none;
          font-size: 30px;
          line-height: 1.3;
        }

        @media #{$large-up} {
          font-size: 42px;
          max-width: 600px;
        }
      }


      > .columns {
        z-index: 1;
      }
    }

    #caw-widget-placeholder {

      @media #{$medium-up} {
        margin-top: $spacing-15;
      }
    }

  }
}
